// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    name: "develop",
    client_id: 3,
    client_secret: 'HvNk3styBQbAF813wwsRBqNUkP21ObTtLgeHSpit',
    cloudinaryRoot: "https://res.cloudinary.com/trustmeals/image/upload",
    api: "https://api.trustmeals.com",
    apiHost: "api.trustmeals.com",
    pk_stripe: "pk_live_QpzmGARd8sXe3ucfcUM5KF1e006vZoarSx",
    businessHost: "https://business.trustmeals.com",
    version: '1.2.5',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
