import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {RedirectGuard} from './guards/redirect.guard';
import {VersionGuard} from "./guards/version.guard";

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./presentation/presentation.module').then(
                (m) => m.PresentationPageModule
            ),
        canActivate: [VersionGuard, RedirectGuard],
        data: { title: 'Meal plan services | Trustmeals'}
    },
    {
        path: 'tabs',
        loadChildren: () =>
            import('./tabs/tabs.module').then((m) => m.TabsPageModule),
        canActivate: [VersionGuard, AuthGuard],
    },
    {
        path: 'business',
        loadChildren: () =>
            import('./business/business.module').then((m) => m.BusinessPageModule),
        canActivate: [VersionGuard, AuthGuard],
    },
    {
        path: 'cart',
        loadChildren: () =>
            import('./cart/cart.module').then((m) => m.CartPageModule),
        canActivate: [VersionGuard, AuthGuard],
        data: { title: 'Cart | Trustmeals'}
    },
    {
        path: 'checkout',
        loadChildren: () =>
            import('./checkout/checkout.module').then((m) => m.CheckoutPageModule),
        canActivate: [VersionGuard, AuthGuard],
        data: { title: 'Checkout | Trustmeals'}
    },
    {
        path: 'add-card',
        loadChildren: () =>
            import('./add-card/add-card.module').then((m) => m.AddCardPageModule),
        canActivate: [VersionGuard, AuthGuard],
        data: { title: 'Add Card | Trustmeals'}
    },
    {
        path: 'thankyou',
        loadChildren: () =>
            import('./thankyou/thankyou.module').then((m) => m.ThankyouPageModule),
        canActivate: [VersionGuard, AuthGuard],
        data: { title: 'Thank you | Trustmeals'}
    },
    {
        path: 'login-email',
        loadChildren: () =>
            import('./login/login.module').then((m) => m.LoginPageModule),
        canActivate: [VersionGuard, RedirectGuard],
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./login-mobile/login-mobile.module').then((m) => m.LoginMobileModule),
        canActivate: [VersionGuard]
    },
    {
        path: 'forgot-password',
        loadChildren: () =>
            import('./forgot/forgot.module').then((m) => m.ForgotPageModule),
        canActivate: [VersionGuard, RedirectGuard],
        data: { title: 'Forgot Password | Trustmeals'}
    },
    {
        path: 'reset-password',
        loadChildren: () =>
            import('./reset/reset.module').then((m) => m.ResetPageModule),
        canActivate: [VersionGuard, RedirectGuard],
        data: { title: 'Reset Password | Trustmeals'}
    },
    {
        path: 'signup',
        loadChildren: () =>
            import('./signup/signup.module').then((m) => m.SignupPageModule),
        canActivate: [VersionGuard, RedirectGuard],
        data: { title: 'Signup | Trustmeals'}
    },
    {
        path: 'user-addresses',
        loadChildren: () => import('./user-addresses/user-addresses.module')
            .then(m => m.UserAddressesPageModule),
        canActivate: [VersionGuard, AuthGuard],
        data: { title: 'Addresses | Trustmeals'}
    },
    {
        path: 'user-wallet',
        loadChildren: () => import('./payment-methods/payment-methods.module')
            .then(m => m.PaymentMethodsPageModule),
        canActivate: [VersionGuard, AuthGuard],
        data: { title: 'Wallet | Trustmeals'}
    },
    {
        path: 'user-order-history',
        loadChildren: () => import('./user-order-history/user-order-history.module')
            .then(m => m.UserOrderHistoryModule),
        canActivate: [VersionGuard, AuthGuard],
        data: { title: 'Order History | Trustmeals'}
    },
    {
        path: 'user-privacy-settings',
        loadChildren: () => import('./user-privacy-settings/user-privacy-settings.module')
            .then(m => m.UserPrivacySettingsPageModule),
        canActivate: [VersionGuard, AuthGuard],
        data: { title: 'Privacy settings | Trustmeals'}
    },
    {
        path: 'user-profile',
        loadChildren: () => import('./user-profile/user-profile.module')
            .then(m => m.UserProfilePageModule),
        canActivate: [VersionGuard, AuthGuard],
        data: { title: 'Profile | Trustmeals'}
    },
    {
        path: 'forgot-password/email-sent',
        loadChildren: () => import('./forgot-email-confirmation/forgot-email-confirmation.module')
            .then(m => m.ForgotEmailConfirmationPageModule)
    },
    {
        path: 'user-password',
        loadChildren: () => import('./user-password/user-password.module')
            .then(m => m.UserPasswordModule),
        canActivate: [VersionGuard, AuthGuard]
    },
    {
        path: 'user-contact-us',
        loadChildren: () => import('./user-contactus/user-contactus.module')
            .then(m => m.UserContactusModule),
        canActivate: [VersionGuard, AuthGuard]
    },
    {
        path: 'version-update',
        loadChildren: () => import('./version-update/version-update.module')
            .then(m => m.VersionUpdateModule),
    },
    {
        path: 'legal',
        loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule),
        data: {title: 'Legal | Trustmeals'},
    },
    {
        path: 'privacy-policy',
        redirectTo: '/legal/privacy-policy'
    },
    {
        path: 'terms-and-conditions',
        redirectTo: '/legal/terms-and-conditions'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
