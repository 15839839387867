import {Injectable} from "@angular/core";
import {
    HttpClient,
    HttpHeaders,
    HttpErrorResponse,
} from "@angular/common/http";
import {throwError as observableThrowError, pipe, Subscription} from "rxjs";
import {environment} from "src/environments/environment";
import {Plugins} from "@capacitor/core";
import {Router} from "@angular/router";

const {Storage} = Plugins;

@Injectable({
    providedIn: "root",
})
export class MicroserviceService {
    httpOptions: {};
    apiKey: string;
    baseURL: string = `${environment.api}/api`;
    httpClient;

    constructor(httpClient: HttpClient) {

        this.httpClient = httpClient;
        this.httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            }),
        };
    }

    /**
     * Error handling
     *
     * @param response
     */
    protected handleError(response: HttpErrorResponse | any) {
        if (response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("cart");
            Storage.remove({key: "token"});
            Storage.remove({key: "cart"});
            window.location.reload(true)
        }
        return observableThrowError(response.error || "Server error");
    }
}
