import { NgModule, Injectable } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CallNumber } from "@ionic-native/call-number/ngx";
import { JwtModule } from "@auth0/angular-jwt";

// Custom Hammer Configuration
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import * as Hammer from "hammerjs";
import { environment } from "src/environments/environment";
import { Plugins } from "@capacitor/core";
import { DesktopHomeComponent } from "./desktop-home/desktop-home.component";

const { Storage } = Plugins;

export async function tokenGetter() {
  const resp = await Storage.get({ key: "token" });
  const token = JSON.parse(resp.value);
  return token ? token.access_token : null;
}

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    let mc = new Hammer(element, {
      touchAction: "auto",
    });
    return mc;
  }
}

@NgModule({
  declarations: [AppComponent, DesktopHomeComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [environment.apiHost],
        blacklistedRoutes: [],
      },
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    CallNumber,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
