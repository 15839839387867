import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable({ providedIn: "root" })
export class RedirectGuard implements CanActivate {
  constructor(private authService: AuthService, private myRoute: Router) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const isLoggednIn = await this.authService.isLoggednIn();
    
    if (isLoggednIn) {
      this.myRoute.navigateByUrl("/tabs/home");
    }

    return true;
  }
}
