import { Component, OnInit } from "@angular/core";

import { Platform } from "@ionic/angular";
import { StateService } from "./services/state.service";
import { Cart } from "./models/cart";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
} from "@angular/router";
import { filter, first } from "rxjs/operators";
import { Plugins, StatusBarStyle } from "@capacitor/core";
import { timer } from "rxjs";
import { StatusBarService } from "./services/capacitor/status-bar.service";
import { Title } from "@angular/platform-browser";
const { SplashScreen } = Plugins;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  cart: Cart = new Cart();
  isShoppingCartRoute: boolean = false;
  splash: boolean = false;

  constructor(
    private platform: Platform,
    private statusBarService: StatusBarService,
    private stateService: StateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationStart))
      .subscribe((e: any) => {
        const routes = [
          "/",
          "/login",
          "/signup",
          "/cart",
          "/checkout",
          "/add-card",
          "/thankyou",
          "/tabs/calendar",
          "/tabs/user",
          "/forgot-password",
          "/version-update",
        ];
        this.isShoppingCartRoute = routes.includes(e.url);

        if (e.url === "/tabs/home") {
          this.statusBarService.setStyle(StatusBarStyle.Light);
        }
      });
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let child = this.activatedRoute.firstChild;

        while (child.firstChild) {
          child = child.firstChild;
        }

        child.data.subscribe((value) => {
          this.titleService.setTitle(value.title);
        });
      }
    });

    timer(100).subscribe(() => {
      this.splash = true;
      SplashScreen.hide();
    });

    timer(3400).subscribe(() => {
      this.splash = false;
    });

    this.getCart();
  }

  get isMobile() {
    return (
      this.platform.is("mobile") ||
      this.platform.is("android") ||
      this.platform.is("iphone") ||
      this.platform.is("cordova") ||
      this.platform.is("ios") ||
      this.platform.is("phablet") ||
      this.platform.is("mobileweb")
    );
  }

  get isIphone() {
    return this.platform.is("mobile") && this.platform.is("iphone");
  }

  get isDesktop() {
    return this.platform.is("desktop");
  }

  async getCart() {
    const resp = await this.stateService.getCart();
    resp.subscribe((cart) => {
      this.cart = cart;
    });
  }
}
