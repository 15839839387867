import { Injectable } from '@angular/core';
import { Plugins, StatusBarStyle } from "@capacitor/core";
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class StatusBarService {

  constructor(private platform: Platform) { }

  setStyle = (style: StatusBarStyle) => {
    if (
      !this.platform.is("desktop") &&
      !this.platform.is("mobileweb") &&
      !this.platform.is("pwa")
    ) {
      Plugins.StatusBar.setStyle({
        style: style,
      });
    }
  }
}
