import {Injectable} from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from "@angular/router";
import {Observable} from "rxjs";
import {AuthService} from "../services/auth.service";
import {StateService} from "../services/state.service";
import {Plugins} from "@capacitor/core";

const {Storage} = Plugins;

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private stateService: StateService, private myRoute: Router) {
    }

    async canActivate(next: ActivatedRouteSnapshot,
                      state: RouterStateSnapshot): Promise<boolean> {
        let isGuest;
        await this.stateService.isGuest().then(value => {
            isGuest = value;
        });

        if (isGuest) {
            return true;
        }

        const token = await this.authService.getDecodedToken();
        if (!token) {
            this.myRoute.navigateByUrl("/");
            return false;
        }

        if (token.role !== "customer") {
            this.authService.logout();
            this.myRoute.navigateByUrl("/login");
            return false;
        }

        if (!this.authService.isLoggednIn()) {
            this.myRoute.navigateByUrl("/login");
            return false;
        }
        const user = await this.stateService.getUser().getValue();
        if (user && !( user.name && user.lastname) && next.url.toString() !== 'personal-info') {
            this.myRoute.navigateByUrl("/login/personal-info");
        } else if (user && user.name && token.lastname && !( user.email) && next.url.toString() !== 'email-info') {
            this.myRoute.navigateByUrl("/login/email-info");
        }

        return true;
    }
}
