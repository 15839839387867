import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {BusinessService} from "./microservices/business.service";
import {Cart} from "../models/cart";
import {Plugins} from "@capacitor/core";
import {InsightsService} from "./microservices/insights.service";
import {InsightTypes} from "../utils/insight-types";

const {Storage} = Plugins;

@Injectable({
    providedIn: "root",
})
export class StateService {
    private user: BehaviorSubject<any> = new BehaviorSubject(null);
    private business: any = new BehaviorSubject(null);
    private cart: BehaviorSubject<Cart> = new BehaviorSubject<Cart>(null);
    paymentMethods: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    defaultPaymentMethod: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private businessService: BusinessService, private insightsService: InsightsService) {
    }

    setUser(newUser) {
        this.user.next(newUser);
    }

    getUser() {
        if (!this.user.getValue()) {
            return this.user;
        } else {
            return this.user;
        }
    }

    setBusiness = (business: any): Observable<any> => {
        this.business.next(business);
        return this.business;
    };

    getBusiness = (id, query): Observable<any> => {
        // if (this.business.getValue() && this.business.getValue().id == id)
        //     return this.business;
        return this.businessService.find(id, query);
    };

    async setCart(newCart: Cart) {
        this.cart.next(newCart);
        await Storage.set({
            key: "cart",
            value: JSON.stringify(newCart),
        });
    }

    async getCart() {
        if (!this.cart.getValue()) {
            const resp = await Storage.get({key: "cart"});
            const storedCart = JSON.parse(resp.value);
            if (storedCart) {
                this.cart.next(storedCart);
            } else {
                this.cart.next(new Cart());
            }
        }

        return this.cart;
    }

    setDefaultPaymentMethod(defaultPaymentMethod) {
        this.defaultPaymentMethod.next(defaultPaymentMethod);
    }

    setPaymentMethods(paymentMethhods) {
        this.paymentMethods.next(paymentMethhods);
    }

    async setGuest() {
        await Storage.set({
            key: "guest",
            value: JSON.stringify(true),
        });
    }

    async isGuest() {
        let flag = false;
        const resp = await Storage.get({key: "guest"});
        const guest = JSON.parse(resp.value);
        if (guest)
            flag = true;
        return flag;
    }

    async guessAddresses() {
        const resp = await Storage.get({key: "addresses"});
        const addresses = JSON.parse(resp.value);
        return addresses;
    }

    insight = (type: string, business_id, source: string = null, metadata = {}) => {
        let data = {
            source: source,
            user: this.getUser().getValue() ? this.getUser().getValue() : 'Guest'
        };
        if (!source) delete data.source;
        if (metadata) data = Object.assign(data, metadata);
        this.insightsService.add(type, business_id, data).subscribe();
    }
}
