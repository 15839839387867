import { Order } from './order';

interface ICart {
    customer: any;
    subtotal: number;
    checkoutSubtotal: number;
    serviceFee: number;
    deliveryFee: number;
    discount: number;
    taxes?: number;
    promocode: any;
    total: number;
    paymentMethod: any;
    orders: Array<Order>;
}

export class Cart implements ICart {
    customer: any = null;
    subtotal: number = 0;
    checkoutSubtotal: number = 0;
    serviceFee: number = 0;
    deliveryFee: number = 0;
    taxes: number = 0;
    discount: number = 0;
    promocode: any = null;
    total: number = 0;
    paymentMethod: any = null;
    orders: Array<Order> = []

    /*
    constructor(data: ICart) {
        this.customer = data.customer;
        this.subtotal = data.subtotal;
        this.serviceFee = data.serviceFee;
        this.discount = data.discount;
        this.promocode = data.promocode;
        this.total = data.total;
        this.paymentMethod = data.paymentMethod;
    }
    */
}