import {Business} from "./../../classes/business";
import {Injectable} from "@angular/core";
import {catchError, map} from "rxjs/operators";
import {Observable} from "rxjs";
import {MicroserviceService} from "./microservice.service";
import {environment} from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class BusinessService extends MicroserviceService {
    /**
     * Get all buisiness
     *
     */
    all(): Observable<any> {
        const $url = `${this.baseURL}/public/business`

        return this.httpClient.get($url, this.httpOptions).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }

    /**
     * Find one business by id
     *
     * @param id
     */
    find(id: number, query: string = ''): Observable<any> {
        const $url = `${this.baseURL}/public/business/${id}${query}`;

        return this.httpClient.get($url, this.httpOptions).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }

    /**
     * Get favorites
     *
     * @param id
     */
    favorites(): Observable<any> {
        const $url = `${this.baseURL}/favorites`;

        return this.httpClient.get($url, this.httpOptions).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }

    /**
     * Add favorite
     *
     * @param id
     */
    addFavorite(business): Observable<any> {
        const $url = `${this.baseURL}/favorites`;

        return this.httpClient.post($url, {
            business_id: business.id
        }, this.httpOptions).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }

    /**
     * Delete favorite
     *
     * @param id
     */
    deleteFavorite(businessId): Observable<any> {
        const $url = `${this.baseURL}/favorites/${businessId}`;

        return this.httpClient.delete($url, this.httpOptions).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }


    /**
     * Get businesses by coordinates
     */
    getByDeliveryAreas(coordinates: any, userId, query = ''): Observable<any> {
        const $url = `${this.baseURL}/public/businesses/delivery_areas/${coordinates}/${userId}` + query;

        return this.httpClient.get($url, this.httpOptions).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }

    /**
     * Get Area by location
     */
    getArea(location: any, businessId, query = ''): Observable<any> {
        const $url = `${this.baseURL}/public/delivery_areas/${businessId}` + query;

        return this.httpClient.post($url, {
            location: location
        }, this.httpOptions).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }
}
