import {Injectable} from "@angular/core";
import {MicroserviceService} from "./microservice.service";
import {Observable} from "rxjs";
import {map, catchError} from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class VersionService extends MicroserviceService {
    /**
     * Check version
     */
    check(version: string, platform: string): Observable<any> {
        const $url = `${this.baseURL}/versions/check`;
        return this.httpClient.post($url, {version: version, platform: platform}, this.httpOptions).pipe(
            map((response: any) => response.data),
            catchError(this.handleError)
        );
    }
}
