import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import decode from "jwt-decode";
import {Plugins} from "@capacitor/core";
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {StateService} from "./state.service";

const {Storage} = Plugins;

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private baseUrl: string = environment.api;
    private httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(private http: HttpClient,
                private router: Router,
                private jwtHelper: JwtHelperService,
                private stateService: StateService) {
    }

    signup(name: string, lastname: string, mobile: string, email: string, password: string): Observable<any> {
        const url = `${this.baseUrl}/api/sign-up`;
        return this.http.post<any>(
            url,
            {
                name,
                lastname,
                mobile,
                email,
                password,
                role: "customer",
            },
            this.httpOptions
        );
    }

    login(username: string, password: string): Observable<any> {
        const url = `${this.baseUrl}/oauth/token`;
        const grant_type = "password";
        const client_id = environment.client_id;
        const client_secret = environment.client_secret;
        return this.http.post(
            url,
            {
                username,
                password,
                grant_type,
                client_id,
                client_secret,
            },
            this.httpOptions
        );
    }

    setToken(newToken) {
        Storage.set({key: "token", value: JSON.stringify(newToken)});
        const tokenDecoded = decode(newToken.access_token);
        this.stateService.setUser({
            id: tokenDecoded.id,
            email: tokenDecoded.email,
            name: tokenDecoded.name,
            lastname: tokenDecoded.lastname,
            role: tokenDecoded.role,
            active: tokenDecoded.active,
            mobile: tokenDecoded.mobile,
        });
    }

    async getToken() {
        const resp = await Storage.get({key: "token"});
        const token = JSON.parse(resp.value);
        return token;
    }

    async getDecodedToken() {
        const token: any = await this.getToken();
        return token ? decode(token.access_token) : null;
    }

    async isLoggednIn() {
        const resp = await Storage.get({key: "token"});
        const token = JSON.parse(resp.value);
        if (token) return !this.jwtHelper.isTokenExpired(token.access_token);
        else return false;
    }

    async logout() {
        await Storage.remove({key: "token"});
        await Storage.remove({key: "cart"});
        await Storage.remove({key: "addresses"});
        this.router.navigateByUrl("/");
    }
}
