import {Injectable} from "@angular/core";
import {MicroserviceService} from "./microservice.service";
import {map, catchError} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
    providedIn: "root",
})
export class InsightsService extends MicroserviceService {
    add = (insightId, businessId, data = {}): Observable<any> => {
        const $url = `${this.baseURL}/insights`;
        const request = {
            insight_type_id: insightId,
            business_id: businessId,
            data: JSON.stringify(data),
        };
        
        return this.httpClient.post($url, request, this.httpOptions).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    };
}
