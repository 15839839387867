import {Injectable} from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from "@angular/router";
import {Observable} from "rxjs";
import {AuthService} from "../services/auth.service";
import {StateService} from "../services/state.service";
import {Plugins} from "@capacitor/core";
import {VersionService} from "../services/microservices/version.service";
import {environment} from "../../environments/environment";
import {Platform} from "@ionic/angular";

const {Storage} = Plugins;

@Injectable({providedIn: "root"})
export class VersionGuard implements CanActivate {
    constructor(private versionService: VersionService,
                private platform: Platform,
                private myRoute: Router) {
    }

    async canActivate(next: ActivatedRouteSnapshot,
                      state: RouterStateSnapshot): Promise<boolean> {
        console.log('platform', this.platform.platforms())
        if (this.platform.is("desktop") || this.platform.is("mobileweb"))
            return true;

        let platform;
        let ok = true;

        if (this.platform.is("mobile") && this.platform.is("ios")) {
            platform = "ios";
        } else if (this.platform.is("mobile") && this.platform.is("android")) {
            platform = "android";
        }

        if (platform) {
            await this.versionService.check(environment.version, platform).subscribe(
                (resp) => {
                },
                (error) => {
                    this.myRoute.navigateByUrl("/version-update");
                    console.log("error", error);
                    ok = false;
                }
            );
        }

        return ok;
    }
}
